import dplogo from '../assets/dp-logo.png';
import danielpinto from '../assets/daniel-pinto.jpeg';
import skill1 from '../assets/html.png';
import skill2 from '../assets/css.png';
import skill3 from '../assets/javascript.png';
import skill4 from '../assets/react.png';
import skill5 from '../assets/node.png';
import skill6 from '../assets/tailwind.png';
import skill7 from '../assets/shopify.png';
import skill8 from '../assets/wordpress.png';
import skill9 from '../assets/gsap.png';
import skill10 from '../assets/github1.png';
import skill11 from '../assets/bigcommerce.png';
import skill12 from '../assets/figma.png';
import skill13 from '../assets/adobexd.png';
import skill14 from '../assets/photoshop.png';
import skill15 from '../assets/illustrator.png';
import skill16 from '../assets/vue-logo.png';

export default {
  dplogo,
  danielpinto,
  skill1,
  skill2,
  skill3,
  skill4,
  skill5,
  skill6,
  skill7,
  skill8,
  skill9,
  skill10,
  skill11,
  skill12,
  skill13,
  skill14,
  skill15,
  skill16,
};
