import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../constants';
import { BsCodeSlash } from 'react-icons/bs';
import { FaNodeJs } from 'react-icons/fa';
import { MdOutlineDesignServices } from 'react-icons/md';
import { AppWrap } from '../wrapper';

const abouts = [
  {
    title: 'Front End Development',
    description: 'I am a good developer',
    icon: <BsCodeSlash />,
  },
  {
    title: 'Back End Development',
    description: 'I am a good developer',
    icon: <FaNodeJs />,
  },
  {
    title: 'Web Design - UX/UI',
    description: 'I am a good developer',
    icon: <MdOutlineDesignServices />,
  },
];

const Skills = () => {
  return (
    <>
      <div className='8xl:w-[67rem] w-full px-8 2xl:px-16 items-center mt-44'>
        <div>
          <h2 className='text-3xl uppercase mb-10'>Skills</h2>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mb-20'>
          {abouts.map((about, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className='flex flex-col'
              key={about.title + index}
            >
              <div className='relative z-10 about-icon border-2 border-white flex items-center justify-center py-16 rounded-[20px] max-w-full bg-neutral-900'>
                {about.icon}
              </div>
              <h3 className='font-bold mt-5 text-center text-xl'>
                {about.title}
              </h3>
              {/* <p className="mt-3 text-center">{about.description}</p> */}
            </motion.div>
          ))}
        </div>
        <div className='grid grid-cols-2 md:grid-cols-5 gap-4'>
          <div className='flex gap-3  flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill1} alt='' width={45} height={45} />
            <p>HTML</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill2} alt='' width={45} height={45} />
            <p>CSS</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill3} alt='' width={45} height={45} />
            <p>JAVASCRIPT</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill4} alt='' width={45} height={45} />
            <p>REACT</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill16} alt='' width={45} height={45} />
            <p>VUE JS</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill5} alt='' width={45} height={45} />
            <p>NODE</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill6} alt='' width={45} height={45} />
            <p>TAILWIND</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill7} alt='' width={45} height={45} />
            <p>SHOPIFY</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill8} alt='' width={45} height={45} />
            <p>WORDPRESS</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill9} alt='' width={45} height={45} />
            <p>GSAP</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill10} alt='' width={45} height={45} />
            <p>GITHUB</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill11} alt='' width={45} height={45} />
            <p>BIGCOMMERCE</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill12} alt='' width={45} height={45} />
            <p>FIGMA</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill13} alt='' width={45} height={45} />
            <p>ADOBE XD</p>
          </div>
          <div className='flex gap-3 flex-col lg:flex-row lg:gap-0 justify-around bg-neutral-700 p-5 items-center rounded-[40px_20px]'>
            <img src={images.skill14} alt='' width={45} height={45} />
            <p>PHOTSHOP</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(Skills, 'skills');
