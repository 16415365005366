import { useState } from 'react';
import { images } from '../constants';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [active, setActive] = useState(null);
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="fixed w-full z-[100] items-center justify-center bg-neutral-900">
      <div className="flex px-8 py-2 2xl:px-16 justify-between items-center max-w-full sm:max-w-6xl mx-auto">
        <div>
          <img src={images.dplogo} alt="logo" width="125" height="125" />
        </div>
        <ul className="hidden sm:flex space-x-10">
          {['home', 'about', 'skills', 'work', 'contact'].map((item) => (
            <li
              key={`link-${item}`}
              onClick={() => setActive(item)}
              className={`group flex items-center gap-2 ${
                active == item && 'menu-active'
              }`}
            >
              <div className="h-1 w-1 bg-transparent rounded-full group-hover:bg-cyan-300 transition duration-300"></div>
              <a
                className="text-sm uppercase transition-all duration-300 hover:text-cyan-300 active:text-cyan-300"
                href={`#${item}`}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex sm:hidden">
          <AiOutlineMenu size="40px" onClick={() => setToggle(true)} />
          {toggle && (
            <motion.div
              whileInView={{ x: [300, 0], opacity: [0.9, 1] }}
              transition={{ duration: 0.85, ease: 'easeInOut' }}
              className="fixed top-0 bottom-0 right-0 z-10 p-6 w-full h-screen flex justify-end items-end flex-col bg-neutral-900 opacity-0"
            >
              <AiOutlineClose size="40px" onClick={() => setToggle(false)} />
              <ul className="flex flex-col h-full m-0 p-0 w-full justify-start items-start space-y-8 sm:hidden ">
                {['home', 'about', 'skills', 'work', 'contact'].map((item) => (
                  <li className="flex flex-col items-center" key={item}>
                    <a
                      className="text-xl uppercase transition-all duration-300 hover:text-cyan-300"
                      href={`#${item}`}
                      onClick={() => setToggle(false)}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
