import React from 'react';
import { Navbar, LightBulb, OrbitControls, Particles } from './components';
import { Header, About, Skills, Work, Contact } from './container';
import { Canvas } from '@react-three/fiber';

const App = () => {
  return (
    <>
      <div className="fixed top-0 left-0 outline-none w-full h-full">
        <Canvas shadows={true} className="">
          <pointLight position={[2, 3, 4]} />
          <LightBulb />
          <Particles />
          <OrbitControls />
        </Canvas>
      </div>
      <div className="flex min-h-screen flex-col items-center relative">
        <Navbar />
        <Header />
        <About />
        <Skills />
        <Work />
        <Contact />
      </div>
    </>
  );
};

export default App;
