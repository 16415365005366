import React from 'react';

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    return (
      <div id={idName} className={`app_container ${classNames}`}>
        <div className="flex flex-col items-center justify-center">
          <Component />
        </div>
      </div>
    );
  };

export default AppWrap;
