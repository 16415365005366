import { images } from '../constants';
import { AppWrap } from '../wrapper';

const About = () => {
  return (
    <div className='flex flex-col items-center lg:flex-row gap-20 justify-between 8xl:w-[67rem] w-full px-8 2xl:px-16 mt-60 lg:mt-0'>
      <div>
        <div className='max-w-full w-[300px]'>
          <img
            src={images.danielpinto}
            alt=''
            className='rounded-[80px_20px]'
          />
        </div>
      </div>
      <div>
        <h2 className=' text-3xl uppercase mb-10'>About me</h2>
        <p className='text-left text-lg '>
          I am a 36 years old Web Developer with 12+ years of experience. I am
          passionate about innovative web design and development. I am an
          enthusiastic, energetic, creative and patient designer with experience
          using programs such as Adobe suite, Visual studio code, and different
          CMS like WordPress, SquareSpace, Shopify, BigCommerce, and Sanity
          Studio. Worked to achieve unique websites and their functionality and
          make them user-friendly and responsive, always understanding clients'
          needs, problems, and solutions. I am skilled in generating sites for
          both desktop and mobile browsers. I combine accepted best practices
          with creative innovation to produce beautiful and functional results.
          I have more than ten years of experience working as a Web Developer. I
          know every part of the process when developing unique websites, as I
          have worked on many projects, from the first meeting to the ongoing
          services. I like to work closely within the team and with clients. I
          like to transform their ideas into an online solution and help to
          bring the ROI expected from a well-built website.
        </p>
      </div>
    </div>
  );
};

export default AppWrap(About, 'about');
