import React from 'react';
import { useFrame } from '@react-three/fiber';
import { PointMaterial } from '@react-three/drei';

function LightBulb() {
  useFrame(({ clock }) => {
    myMesh.current.rotation.y = clock.getElapsedTime();
  });
  const myMesh = React.useRef();

  return (
    <mesh ref={myMesh} scale={2.2}>
      <points>
        <torusBufferGeometry args={[0.7, 0.2, 16, 100]} />
        <PointMaterial size="0.005" />
      </points>
    </mesh>
  );
}

export default LightBulb;
