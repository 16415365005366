import { AppWrap } from '../wrapper';

const Header = () => {
  return (
    <div className="flex justify-center items-center max-w-6xl w-full px-8 2xl:px-16 ">
      <div>
        <p className="text-5xl leading-tight text-center">
          Hello, I'm Daniel Pinto <br /> Web Developer
        </p>
      </div>
    </div>
  );
};

export default AppWrap(Header, 'home');
