import { images } from '../constants';
import { BsEnvelope, BsPhone } from 'react-icons/bs';
import { AppWrap } from '../wrapper';

const Contact = () => {
  return (
    <div className='flex flex-col justify-center max-w-6xl w-full px-8 2xl:px-16 h-full relative'>
      <div>
        <h2 className='text-3xl uppercase mb-12 text-center'>Contact me</h2>
        <div className='flex justify-start items-center p-4 bg-neutral-700 rounded-md w-72 max-w-full mb-8 border-cyan-300 border-solid border-2'>
          <BsEnvelope className='text-2xl mr-4' />
          <a
            className='text-lg hover:text-cyan-500 transition-all ease-in duration-100'
            href='mailto:daniel@cosmoweb.co.nz'
          >
            daniel@cosmoweb.co.nz
          </a>
        </div>
        <div className='flex justify-start items-center p-4 bg-neutral-700 rounded-md w-72 max-w-full mb-8 border-cyan-300 border-solid border-2'>
          <BsPhone className='text-2xl mr-4' />
          <a
            className='text-md hover:text-cyan-500 transition-all ease-in duration-100'
            href='tel:+6402040272418'
          >
            +64 02040272418
          </a>
        </div>
        <p className='text-md uppercase text-white text-center mb-4 absolute left-2/4 bottom-0 -translate-x-2/4 w-full '>
          @2023 Daniel Pinto <br /> All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default AppWrap(Contact, 'contact');
