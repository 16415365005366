import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap } from '../wrapper';
import { urlFor, client } from '../client';

const Work = () => {
  const [activeFilter, setactiveFilter] = useState('All');
  const [animateCard, setanimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  const handleWorkFilter = (item) => {
    setactiveFilter(item);
    setanimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setanimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <div className="w-full h-full px-8 2xl:px-16 mt-48">
        <div className="h-full 8xl:w-[67rem] px:4 lg:px-8">
          <h2 className="text-3xl uppercase mb-10">Work</h2>
          <div className="flex flex-row justify-start items-center flex-wrap gap-3">
            {[
              'WordPress',
              'WooCommerce',
              'Shopify',
              'BigCommerce',
              'React',
              'Next JS',
              'Tailwind',
              'All',
            ].map((item, index) => (
              <div
                key={index}
                onClick={() => handleWorkFilter(item)}
                className={`py-2 px-4 rounded-md bg-neutral-700 text-neutral-100 font-bold cursor-pointer transition-all ease-in-out duration-150 hover:bg-cyan-300 hover:text-neutral-800 ${
                  activeFilter === item ? 'item-active' : ''
                }`}
              >
                {' '}
                {item}
              </div>
            ))}
          </div>
          <motion.div
            animate={animateCard}
            transition={{ duration: 0.5, delayChildren: 0.5 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 row-auto gap-5 mt-8"
          >
            {filterWork.map((work, index) => (
              <div
                className="flex flex-col p-4 rounded-lg bg-neutral-700 text-neutral-100 cursor-pointer transition-all duration-300 hover:shadow-sm"
                key={index}
              >
                <div className="flex w-full h-60 relative ">
                  <img
                    src={urlFor(work.imgUrl)}
                    alt={work.name}
                    className="object-cover w-full h-full rounded-lg"
                  />
                  <motion.div
                    whileHover={{ opacity: [0, 1] }}
                    transition={{
                      duration: 0.25,
                      ease: 'easeInOut',
                      staggerChildren: 0.5,
                    }}
                    className="flex absolute inset-0 rounded-lg bg-[#000] bg-opacity-50  justify-center opacity-0 "
                  >
                    <a
                      href={work.projectLink}
                      target="blank"
                      rel="noreferrer "
                      className="flex items-end mb-8"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ opacity: [1, 0.9] }}
                        transition={{
                          duration: 0.25,
                        }}
                        className="flex items-center justify-center w-12 h-12 rounded-full bg-neutral-700 m-4 cursor-pointer hover:scale-50"
                      >
                        <AiFillEye className="w-2/4 h-2/4 fill-cyan-500" />
                      </motion.div>
                    </a>
                    <a
                      href={work.codeLink}
                      target="blank"
                      rel="noreferrer "
                      className="flex items-end mb-8"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ opacity: [1, 0.9] }}
                        transition={{
                          duration: 0.25,
                        }}
                        className="flex items-center justify-center w-12 h-12 rounded-full bg-neutral-700 m-4 cursor-pointer"
                      >
                        <AiFillGithub className="w-2/4 h-2/4 fill-white bg-neutral-700" />
                      </motion.div>
                    </a>
                  </motion.div>
                </div>

                <div className="flex p-2 w-full relative flex-col">
                  <h4 className="text-bold mt-4">{work.title}</h4>
                  <p className="text-sm mt-3">{work.description}</p>

                  <div className="flex left-2/4 -translate-x-1/2 absolute py-2 px-4 rounded-xl bg-neutral-700 -top-6 ">
                    <p className="text-sm">{work.tags[0]}</p>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(Work, 'work');
