import React from 'react';
import { Stars } from '@react-three/drei';

const Particles = () => (
  <mesh>
    <Stars
      radius={100}
      depth={80}
      count={1000}
      factor={6}
      saturation={3}
      fade
      speed={2}
    />
    <Stars
      radius={100}
      depth={100}
      count={3000}
      factor={2}
      saturation={1}
      fade
      speed={6}
    />
  </mesh>
);

export default Particles;
